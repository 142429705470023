import React, { useState } from "react";
import { Button } from "semantic-ui-react";
import "./ScrollButton.css";
import { createMedia } from "@artsy/fresnel";
import { useNavigate } from "react-router-dom";

const { MediaContextProvider, Media } = createMedia({
  breakpoints: {
    mobile: 0,
    computer: 1024,
  },
});

export default function ScrollButton() {
  const [visible, setVisible] = useState(false);
  const navigator = useNavigate();

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setVisible(true);
    } else if (scrolled <= 300) {
      setVisible(false);
    }
  };

  const scrollToTop = () => {
    navigator("/home");
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  window.addEventListener("scroll", toggleVisible);

  return (
    <div>
      <MediaContextProvider>
        <Media greaterThan="mobile">
          <Button
            icon="arrow up"
            size="big"
            color="green"
            className="btn-scroll me-3"
            onClick={scrollToTop}
            style={{ display: visible ? "inline" : "none" }}
          ></Button>
        </Media>
        <Media at="mobile">
          <Button
            color="green"
            size="massive"
            icon="arrow up"
            className="btn-scroll"
            style={{
              display: visible ? "inline" : "none",
              height: "130px",
              width: "130px",
            }}
            onClick={scrollToTop}
          ></Button>
        </Media>
      </MediaContextProvider>
    </div>
  );
}
