import React from "react";
import skillsPath from "../../assets/files/skills.pdf";
import { Button } from "semantic-ui-react";
import { createMedia } from "@artsy/fresnel";

const { MediaContextProvider, Media } = createMedia({
  breakpoints: {
    mobile: 0,
    computer: 1024,
  },
});

export default function SkillsViewer(props) {
  return (
    <div>
      <MediaContextProvider>
        <Media greaterThan="mobile">
          <Button
            href={skillsPath}
            target="_blank"
            rel="noreferrer"
            size="huge"
            content={props.btnText}
            floated={props.floated}
            color={props.color}
          ></Button>
        </Media>{" "}
        <Media at="mobile">
          <Button
            href={skillsPath}
            target="_blank"
            rel="noreferrer"
            size="huge"
            content={props.btnText}
            floated={props.floated}
            color={props.color}
            
            style={{ fontSize: "45px" }}
            className="mt-1"
          ></Button>
        </Media>
      </MediaContextProvider>
    </div>
  );
}
