import React, { forwardRef } from "react";
import { Grid, Header, Icon, Image } from "semantic-ui-react";
import { images } from "../../constants/Images";
import { createMedia } from "@artsy/fresnel";
import SkillsViewer from "../resume/SkillsViewer";

const { MediaContextProvider, Media } = createMedia({
  breakpoints: {
    mobile: 0,
    computer: 1024,
  },
});

const SkillsComponent = forwardRef(function (_, ref) {
  return (
    <div ref={ref}>
      <MediaContextProvider>
        <Media greaterThan="mobile">
          <Grid container stackable verticalAlign="middle">
            <Grid.Row className="mb-5">
              <Grid.Column width={8}>
                <Header className="home-header text-start">Skills</Header>
                <p style={{ fontSize: "1.33em", textAlign: "start" }}>
                  I know logic of software development. So I can develop your
                  project in any environment, any language etc. I studied with
                  .NET, Spring, React, Angular before.
                </p>
                <SkillsViewer
                  color="black"
                  btnText="Show more skills"
                  floated="left"
                ></SkillsViewer>
              </Grid.Column>
              <Grid.Column floated="right" width={6}>
                <Grid>
                  <Grid.Row columns={2}>
                    <Grid.Column>
                      <Image rounded size="small" src={images["dotnetSkill"]} />
                    </Grid.Column>
                    <Grid.Column>
                      <Image rounded size="small" src={images["springSkill"]} />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row columns={2}>
                    <Grid.Column>
                      <Image
                        rounded
                        size="small"
                        src={images["angularSkill"]}
                      />
                    </Grid.Column>
                    <Grid.Column>
                      <Image rounded size="small" src={images["reactSkill"]} />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Grid.Column>
            </Grid.Row>
          </Grid>

          <Grid columns="equal" stackable>
            <Grid.Row textAlign="center" centered className="ps-2 pe-2">
              <Grid.Column width={"6"} className="pb-5 pt-5">
                <Header as="h3" className="fs-2">
                  <Icon name="lightbulb" />
                  Logic of development
                </Header>
                <p className="fs-3">
                  <b>Sustainability</b> is the keyword in software development.
                  All principles, techniques, rules emerged from this key
                  element. Quality of a software, is evaluated according to its
                  sustainability. I do what I do considering all these.
                </p>
              </Grid.Column>
              <Grid.Column width={"6"} className="pb-5 pt-5">
                <Header as="h3" className="fs-2">
                  <Icon name="sitemap" />
                  Solving problems
                </Header>
                <p className="fs-3">
                  After 5 years experience in software development, I solved a
                  lot of problems and I learned solving techniques and logic of
                  lots of errors. I have a deep knowledge on how to solve
                  problems.
                </p>
              </Grid.Column>
            </Grid.Row>
            {/* Divider */}
            <Grid.Row textAlign="center" centered className="ps-2 pe-2">
              <Grid.Column width={"6"} className="pt-5 pb-5">
                <Header as="h3" className="fs-2">
                  <Icon name="settings" />
                  Project management
                </Header>
                <p className="fs-3">
                  I managed a lot of projects before. Sometimes I developed
                  alone, sometimes with a team. I learned how to manage a
                  software project, I can guess which dangerous possibilities
                  can will be in future, How to manage the process etc.
                </p>
              </Grid.Column>
              <Grid.Column width={"6"} className="pt-5 pb-5">
                <Header as="h3" className="fs-2">
                  <Icon name="users" />
                  Team working
                </Header>
                <p className="fs-3">
                  I am compatible with team works. I'll accept my{" "}
                  <b>mistakes</b>, I work for fix them. You can communicate with
                  me easily.
                </p>
                <p className="fs-3">
                  I love
                  <span className="text-red"> fun</span>,
                  <span className="text-green"> games</span>,
                  <span className="text-blue"> team events</span>.
                </p>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Media>
        <Media at="mobile">
          <Grid container stackable verticalAlign="middle">
            <Grid.Row>
              <Grid.Column floated="left">
                <Grid>
                  <Grid.Row columns={2}>
                    <Grid.Column>
                      <Image rounded size="big" src={images["dotnetSkill"]} />
                    </Grid.Column>
                    <Grid.Column>
                      <Image rounded size="big" src={images["springSkill"]} />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row columns={2}>
                    <Grid.Column>
                      <Image rounded size="big" src={images["angularSkill"]} />
                    </Grid.Column>
                    <Grid.Column>
                      <Image rounded size="big" src={images["reactSkill"]} />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row className="mb-5">
              <Grid.Column>
                <Header className="home-header text-start">Skills</Header>
                <p style={{ fontSize: "3.6em", textAlign: "start" }}>
                  I know logic of software development. So I can develop your
                  project in any environment, any language etc. I studied with
                  .NET, Spring, React, Angular before.
                </p>
                <SkillsViewer
                  color="black"
                  btnText="Show more skills"
                  floated="left"
                ></SkillsViewer>
              </Grid.Column>
            </Grid.Row>
          </Grid>

          <Grid columns="equal" stackable>
            <Grid.Row textAlign="center" centered className="ps-2 pe-2">
              <Grid.Column width={12} className="pb-5 pt-5">
                <Header as="h3" style={{ fontSize: "4.2em" }}>
                  <Icon name="lightbulb" />
                  Logic of development
                </Header>
                <p className="fs-1">
                  <b>Sustainability</b> is the keyword in software development.
                  All principles, techniques, rules emerged from this key
                  element. Quality of a software, is evaluated according to its
                  sustainability. I do what I do considering all these.
                </p>
              </Grid.Column>
              <Grid.Column width={12} className="pb-5 pt-5">
                <Header as="h3" style={{ fontSize: "4.2em" }}>
                  <Icon name="sitemap" style={{ marginRight: "0.3em" }} />
                  Solving problems
                </Header>
                <p className="fs-1">
                  After 5 years experience in software development, I solved a
                  lot of problems and I learned solving techniques and logic of
                  lots of errors. I have a deep knowledge on how to solve
                  problems.
                </p>
              </Grid.Column>
            </Grid.Row>
            {/* Divider */}
            <Grid.Row textAlign="center" centered className="ps-2 pe-2">
              <Grid.Column width={12} className="pt-5 pb-5">
                <Header as="h3" style={{ fontSize: "4.2em" }}>
                  <Icon name="settings" style={{ marginRight: "0.3em" }} />
                  Project management
                </Header>
                <p className="fs-1">
                  I managed a lot of projects before. Sometimes I developed
                  alone, sometimes with a team. I learned how to manage a
                  software project, I can guess which dangerous possibilities
                  can will be in future, How to manage the process etc.
                </p>
              </Grid.Column>
              <Grid.Column width={12} className="pt-5 pb-5">
                <Header as="h3" style={{ fontSize: "4.2em" }}>
                  <Icon name="users" style={{ marginRight: "0.3em" }} />
                  Team working
                </Header>
                <p className="fs-1">
                  I am compatible with team works. I'll accept my{" "}
                  <b>mistakes</b>, I work for fix them. You can communicate with
                  me easily.
                </p>
                <p className="fs-1">
                  I love fun, games, team events.
                </p>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Media>
      </MediaContextProvider>
    </div>
  );
});

export default SkillsComponent;
