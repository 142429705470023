import React, { Component } from "react";
import {
  Container,
  List,
  Grid,
  Header,
  Segment,
  Icon,
} from "semantic-ui-react";
import { links } from "../../../constants/Links";
import { createMedia } from "@artsy/fresnel";

const { MediaContextProvider, Media } = createMedia({
  breakpoints: {
    mobile: 0,
    computer: 1024,
  },
});

export default class Footer extends Component {
  render() {
    return (
      <div>
        <MediaContextProvider>
          <Media greaterThan="mobile">
            <Segment inverted vertical style={{ padding: "5em 0em" }}>
              <Container>
                <Grid inverted stackable>
                  <Grid.Row>
                    <Grid.Column width={8}>
                      <Header inverted floated="left" className="fs-5 fw-n">
                        © Copyright 2023 | All Rights Reserved | Website by Me
                        :)
                      </Header>
                    </Grid.Column>
                    <Grid.Column width={8}>
                      <List inverted floated="right" horizontal>
                        <List.Item target="_blank" href={links["github"]}>
                          <Icon name="github" size="large" />
                        </List.Item>
                        <List.Item target="_blank" href={links["instagram"]}>
                          <Icon name="instagram" size="large" />
                        </List.Item>
                        <List.Item target="_blank" href={links["linkedin"]}>
                          <Icon name="linkedin" size="large" />
                        </List.Item>
                      </List>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Container>
            </Segment>
          </Media>
          <Media at="mobile">
            <Segment inverted vertical style={{ padding: "12em 3em" }}>
              <Container fluid>
                <Grid inverted stackable>
                  <Grid.Row>
                    <Grid.Column width={8}>
                      <Header inverted floated="left" className="fs-1 fw-n">
                        © Copyright 2023 | All Rights Reserved | Website by Me
                        :)
                      </Header>
                    </Grid.Column>
                    <Grid.Column width={8}>
                      <List size="massive" inverted floated="right" horizontal>
                        <List.Item  target="_blank" href={links["github"]}>
                          <Icon name="github" size="big" />
                        </List.Item>
                        <List.Item target="_blank" href={links["instagram"]}>
                          <Icon name="instagram" size="big" />
                        </List.Item>
                        <List.Item target="_blank" href={links["linkedin"]}>
                          <Icon name="linkedin" size="big" />
                        </List.Item>
                      </List>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Container>
            </Segment>
          </Media>
        </MediaContextProvider>
      </div>
    );
  }
}
