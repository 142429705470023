import AllentLogo from "../assets/images/allent.webp";
import Home1 from "../assets/images/home1.webp";
import KitabeLogo from "../assets/images/kitabe.webp";
import SorulukLogo from "../assets/images/soruluk.webp";
import TransportathonLogo from "../assets/images/transportathon.webp";
import DotnetSkill from "../assets/skills/dotnet.webp";
import SpringSkill from "../assets/skills/spring.webp";
import ReactSkill from "../assets/skills/react.webp";
import AngularSkill from "../assets/skills/angular.webp";

export const images = {
  allentLogo: AllentLogo,
  kitabeLogo: KitabeLogo,
  sorulukLogo: SorulukLogo,
  transportathonLogo: TransportathonLogo,
  home1: Home1,
  dotnetSkill: DotnetSkill,
  springSkill: SpringSkill,
  angularSkill: AngularSkill,
  reactSkill: ReactSkill,
};
